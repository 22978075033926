/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'
import ToggleField from '@e2grnd/ui-core/dist/containers/ToggleField'

function Step2({ style, problemType, whichNetwork21, whichNetwork2, whichNetwork2a, whichNetwork2b, componentAge, componentThickness, undertolerance, criticalFlawDepthVarType, criticalFlawDepth, criticalFlawDepthSd, criticalFlawDepthCv, criticalFlawDepthConfidence, innerDiameter, failureThicknessMethod, internalPressure, flowStress, failureThickness, failureThicknessConfidence, gff, componentAgeVsInstallDateBool, timeSinceInstall, calculateRiskFlag, consequence }) {
  return (
    <div className="cf w-100">
      {(whichNetwork2a === '885 Embrittlement' || whichNetwork2a === 'Alkaline Carbonate SCC' || whichNetwork2a === 'Amine Cracking' || whichNetwork2a === 'Brittle Fracture' || whichNetwork2a === 'Caustic SCC' || whichNetwork2a === 'Chloride SCC' || whichNetwork2a === 'HTHA Damage' || whichNetwork2a === 'Hydrogen Induced Cracking in H2S' || whichNetwork2a === 'Hydrogen Induced Cracking in HF' || whichNetwork2a === 'Hydrogen SCC in HF' || whichNetwork2a === 'Low Alloy Embrittlement' || whichNetwork2a === 'Mechanical Fatigue' || whichNetwork2a === 'Other Cracking' || whichNetwork2a === 'Poly Acid SCC' || whichNetwork2a === 'Sigma Phase Embrittlement' || whichNetwork2a === 'Sulfide SCC') && whichNetwork21 === 'Cracking'
        ? (
          <div>
            <E2GTextField name="gff" value={gff} label="Generic Failure Frequency" type="number" required />
            {whichNetwork2a === 'Brittle Fracture' || whichNetwork2a === 'Low Alloy Embrittlement'
              ? (
                <E2GTextField name="componentThickness" value={componentThickness} label="Component Nominal Thickness" type="number" required />
              )
              : ''}
            {/* <E2GTextField name="undertolerance" value={undertolerance} label="Component Undertolerance" type="number" required /> */}
            {/* <E2GTextField name="componentAge" value={componentAge} label="Component Age" type="number" required /> */}
            {componentAgeVsInstallDateBool === true
              ? (
                <E2GTextField name="componentAge" value={componentAge} label="Component Age" type="number" required />
              )
              : <E2GTextField name="timeSinceInstall" value={timeSinceInstall} label="Component Install/Last Replacement Date" type="string" required />}
            {/* <E2GSelectField name="criticalFlawDepthVarType" value={criticalFlawDepthVarType} label="Critical Flaw Depth Type">
              <E2GSelectFieldOption label="SD" value="sd" />
              <E2GSelectFieldOption label="Confidence" value="Confidence" />
            </E2GSelectField>
            <E2GTextField name="criticalFlawDepth" value={criticalFlawDepth} label="Critical Flaw Depth" type="number" required />
            {criticalFlawDepthVarType === 'sd'
              ? (
                <E2GTextField name="criticalFlawDepthSd" value={criticalFlawDepthSd} label="Critical Flaw Depth SD" type="number" required />
              )
              : ''}
            {criticalFlawDepthVarType === 'cv'
              ? (
                <E2GTextField name="criticalFlawDepthCv" value={criticalFlawDepthCv} label="Critical Flaw Depth CV" type="number" required />
              )
              : ''}
            {criticalFlawDepthVarType === 'Confidence'
              ? (
                <E2GSelectField name="criticalFlawDepthConfidence" value={criticalFlawDepthConfidence} label="Critical Flaw Depth Confidence">
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
              )
              : ''} */}
          </div>
        )
        : ''}
      {(whichNetwork2 === '885 Embrittlement' || whichNetwork2 === 'Alkaline Carbonate SCC' || whichNetwork2 === 'Amine Cracking' || whichNetwork2 === 'Brittle Fracture' || whichNetwork2 === 'Caustic SCC' || whichNetwork2 === 'Chloride SCC' || whichNetwork2 === 'HTHA Damage' || whichNetwork2 === 'Hydrogen Induced Cracking in H2S' || whichNetwork2 === 'Hydrogen Induced Cracking in HF' || whichNetwork2 === 'Hydrogen SCC in HF' || whichNetwork2 === 'Low Alloy Embrittlement' || whichNetwork2 === 'Mechanical Fatigue' || whichNetwork2 === 'Other Cracking' || whichNetwork2 === 'Poly Acid SCC' || whichNetwork2 === 'Sigma Phase Embrittlement' || whichNetwork2 === 'Sulfide SCC') && whichNetwork21 === 'unknown'
        ? (
          <div>
            <E2GTextField name="gff" value={gff} label="Generic Failure Frequency" type="number" required />
            {whichNetwork2 === 'Brittle Fracture' || whichNetwork2 === 'Low Alloy Embrittlement'
              ? (
                <E2GTextField name="componentThickness" value={componentThickness} label="Component Nominal Thickness" type="number" required />
              )
              : ''}
            {/* <E2GTextField name="undertolerance" value={undertolerance} label="Component Undertolerance" type="number" required /> */}
            {/* <E2GTextField name="componentAge" value={componentAge} label="Component Age" type="number" required /> */}
            {componentAgeVsInstallDateBool === true
              ? (
                <E2GTextField name="componentAge" value={componentAge} label="Component Age" type="number" required />
              )
              : <E2GTextField name="timeSinceInstall" value={timeSinceInstall} label="Component Install/Last Replacement Date" type="string" required />}
            {/* <E2GSelectField name="criticalFlawDepthVarType" value={criticalFlawDepthVarType} label="Critical Flaw Depth Type">
              <E2GSelectFieldOption label="SD" value="sd" />
              <E2GSelectFieldOption label="Confidence" value="Confidence" />
            </E2GSelectField>
            <E2GTextField name="criticalFlawDepth" value={criticalFlawDepth} label="Critical Flaw Depth" type="number" required />
            {criticalFlawDepthVarType === 'sd'
              ? (
                <E2GTextField name="criticalFlawDepthSd" value={criticalFlawDepthSd} label="Critical Flaw Depth SD" type="number" required />
              )
              : ''}
            {criticalFlawDepthVarType === 'cv'
              ? (
                <E2GTextField name="criticalFlawDepthCv" value={criticalFlawDepthCv} label="Critical Flaw Depth CV" type="number" required />
              )
              : ''}
            {criticalFlawDepthVarType === 'Confidence'
              ? (
                <E2GSelectField name="criticalFlawDepthConfidence" value={criticalFlawDepthConfidence} label="Critical Flaw Depth Confidence">
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
              )
              : ''} */}
          </div>
        )
        : ''}
      {(whichNetwork2b === 'Acid Sour Water Corrosion' || whichNetwork2b === 'Alkaline Sour Water Corrosion' || whichNetwork2b === 'Amine Corrosion' || whichNetwork2b === 'CO2 Corrosion' || whichNetwork2b === 'Cooling Water Corrosion' || whichNetwork2b === 'HF Acid Corrosion' || whichNetwork2b === 'High Temperature H2-H2S Corrosion' || whichNetwork2b === 'High Temperature Naphthenic Corrosion' || whichNetwork2b === 'High Temperature Oxidation Corrosion' || whichNetwork2b === 'Hydrochloric Acid Corrosion' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Soil Side Corrosion' || whichNetwork2b === 'Sulfuric Acid Corrosion' || whichNetwork2b === 'Tank Bottom Corrosion' || whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning'
        ? (
          <div>
            <div className="fl w-third-l w-50-m w-100">
              <div className="cf mv2 w-100">
                <E2GTextField name="componentThickness" value={componentThickness} label="Component Nominal Thickness" type="number" required />
              </div>
              <E2GTextField name="undertolerance" value={undertolerance} label="Component Undertolerance" type="number" required />
              {/* <E2GTextField name="componentAge" value={componentAge} label="Component Age" type="number" required /> */}
              {componentAgeVsInstallDateBool === true
                ? (
                  <E2GTextField name="componentAge" value={componentAge} label="Component Age" type="number" required />
                )
                : <E2GTextField name="timeSinceInstall" value={timeSinceInstall} label="Component Install/Last Replacement Date" type="string" required />}
              <E2GTextField name="innerDiameter" value={innerDiameter} label="Inner Diameter" type="number" required />
              {whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'High Temperature Naphthenic Corrosion' || whichNetwork2b === 'High Temperature H2-H2S Corrosion'|| whichNetwork2b === 'Ammonium Chloride Corrosion' || whichNetwork2b === 'Other Corrosion'
                ? (
                  <div>
                    <ToggleField name="calculateRiskFlag" value={calculateRiskFlag} label="Calculate Risk?" />
                    { calculateRiskFlag === true
                      ? (
                        <E2GTextField name="consequence" value={consequence} label="Consequence" type="number" required />
                      )
                      : ''}
                  </div>
                )
                : ''}
            </div>
            <div className="fl w-third-l w-50-m w-100">
              <E2GSelectField name="failureThicknessMethod" value={failureThicknessMethod} label="Failure Thickness Method">
                <E2GSelectFieldOption label="Specify" value="specify" />
                <E2GSelectFieldOption label="Calculate" value="calculate" />
              </E2GSelectField>
              {failureThicknessMethod === 'calculate'
                ? (
                  <div>
                    <E2GTextField name="internalPressure" value={internalPressure} label="Internal Pressure" type="number" required />
                    <E2GTextField name="flowStress" value={flowStress} label="Flow Stress" type="number" required />
                  </div>
                )
                : ''}
              {failureThicknessMethod !== 'calculate'
                ? (
                  <div>
                    <E2GTextField name="failureThickness" value={failureThickness} label="Component Failure Thickness" type="number" required />
                  </div>
                )
                : ''}
              <E2GSelectField name="failureThicknessConfidence" value={failureThicknessConfidence} label="Failure Thickness Confidence">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Low" value="Low" />
                <E2GSelectFieldOption label="Moderate" value="Moderate" />
                <E2GSelectFieldOption label="High" value="High" />
              </E2GSelectField>
            </div>
          </div>
        )
        : ''}
      {(whichNetwork2 === 'Acid Sour Water Corrosion' || whichNetwork2 === 'Alkaline Sour Water Corrosion' || whichNetwork2 === 'Amine Corrosion' || whichNetwork2 === 'CO2 Corrosion' || whichNetwork2 === 'Cooling Water Corrosion' || whichNetwork2 === 'HF Acid Corrosion' || whichNetwork2 === 'High Temperature H2-H2S Corrosion' || whichNetwork2 === 'High Temperature Naphthenic Corrosion' || whichNetwork2 === 'High Temperature Oxidation Corrosion' || whichNetwork2 === 'Hydrochloric Acid Corrosion' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Soil Side Corrosion' || whichNetwork2 === 'Sulfuric Acid Corrosion' || whichNetwork2 === 'Tank Bottom Corrosion' || whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'High Temperature H2-H2S Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown'
        ? (
          <div>
            <div className="fl w-third-l w-50-m w-100">
              <div className="cf mv2 w-100">
                <E2GTextField name="componentThickness" value={componentThickness} label="Component Nominal Thickness" type="number" required />
              </div>
              <E2GTextField name="undertolerance" value={undertolerance} label="Component Undertolerance" type="number" required />
              {/* <E2GTextField name="componentAge" value={componentAge} label="Component Age" type="number" required /> */}
              {componentAgeVsInstallDateBool === true
                ? (
                  <E2GTextField name="componentAge" value={componentAge} label="Component Age" type="number" required />
                )
                : <E2GTextField name="timeSinceInstall" value={timeSinceInstall} label="Component Install/Last Replacement Date" type="string" required />}
              <E2GTextField name="innerDiameter" value={innerDiameter} label="Inner Diameter" type="number" required />
              {whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'High Temperature Naphthenic Corrosion'|| whichNetwork2 === 'Ammonium Chloride Corrosion' || whichNetwork2 === 'Other Corrosion'
                ? (
                  <div>
                    <ToggleField name="calculateRiskFlag" value={calculateRiskFlag} label="Calculate Risk?" />
                    { calculateRiskFlag === true
                      ? (
                        <E2GTextField name="consequence" value={consequence} label="Consequence" type="number" required />
                      )
                      : ''}
                  </div>
                )
                : ''}
            </div>
            <div className="fl w-third-l w-50-m w-100">
              <E2GSelectField name="failureThicknessMethod" value={failureThicknessMethod} label="Failure Thickness Method">
                <E2GSelectFieldOption label="Specify" value="specify" />
                <E2GSelectFieldOption label="Calculate" value="calculate" />
              </E2GSelectField>
              {failureThicknessMethod === 'calculate'
                ? (
                  <div>
                    <E2GTextField name="internalPressure" value={internalPressure} label="Internal Pressure" type="number" required />
                    <E2GTextField name="flowStress" value={flowStress} label="Flow Stress" type="number" required />
                  </div>
                )
                : ''}
              {failureThicknessMethod !== 'calculate'
                ? (
                  <div>
                    <E2GTextField name="failureThickness" value={failureThickness} label="Component Failure Thickness" type="number" required />
                  </div>
                )
                : ''}
              <E2GSelectField name="failureThicknessConfidence" value={failureThicknessConfidence} label="Failure Thickness Confidence">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Low" value="Low" />
                <E2GSelectFieldOption label="Moderate" value="Moderate" />
                <E2GSelectFieldOption label="High" value="High" />
              </E2GSelectField>
            </div>
          </div>
        )
        : ''}
      <div className="fl w-100">
        <Submit label="Next" />
      </div>
    </div>
  )
}
Step2.propTypes = {
  style: PropTypes.object,
  problemType: PropTypes.string,
  whichNetwork21: PropTypes.string,
  whichNetwork2: PropTypes.string,
  whichNetwork2a: PropTypes.string,
  whichNetwork2b: PropTypes.string,
  componentThickness: PropTypes.number,
  undertolerance: PropTypes.number,
  innerDiameter: PropTypes.number,
  failureThicknessMethod: PropTypes.string,
  internalPressure: PropTypes.number,
  flowStress: PropTypes.number,
  failureThickness: PropTypes.number,
  failureThicknessConfidence: PropTypes.string,
  criticalFlawDepthVarType: PropTypes.string,
  criticalFlawDepth: PropTypes.number,
  criticalFlawDepthSd: PropTypes.number,
  criticalFlawDepthCv: PropTypes.number,
  criticalFlawDepthConfidence: PropTypes.string,
  componentAge: PropTypes.number,
  gff: PropTypes.number,
  consequence: PropTypes.number,
  timeSinceInstall: PropTypes.string,
  componentAgeVsInstallDateBool: PropTypes.bool,
  calculateRiskFlag: PropTypes.bool,
}

const mapStateToProps = state => ({
  style: state.ui.style,
  problemType: state.workflow.fields.problemType,
  consequence: state.workflow.fields.consequence,
  whichNetwork21: state.workflow.fields.whichNetwork21,
  whichNetwork2: state.workflow.fields.whichNetwork2,
  whichNetwork2a: state.workflow.fields.whichNetwork2a,
  whichNetwork2b: state.workflow.fields.whichNetwork2b,
  componentThickness: state.workflow.fields.componentThickness,
  undertolerance: state.workflow.fields.undertolerance,
  innerDiameter: state.workflow.fields.innerDiameter,
  failureThicknessMethod: state.workflow.fields.failureThicknessMethod,
  internalPressure: state.workflow.fields.internalPressure,
  flowStress: state.workflow.fields.flowStress,
  failureThickness: state.workflow.fields.failureThickness,
  failureThicknessConfidence: state.workflow.fields.failureThicknessConfidence,
  criticalFlawDepthVarType: state.workflow.fields.criticalFlawDepthVarType,
  criticalFlawDepth: state.workflow.fields.criticalFlawDepth,
  criticalFlawDepthSd: state.workflow.fields.criticalFlawDepthSd,
  criticalFlawDepthCv: state.workflow.fields.criticalFlawDepthCv,
  criticalFlawDepthConfidence: state.workflow.fields.criticalFlawDepthConfidence,
  componentAge: state.workflow.fields.componentAge,
  gff: state.workflow.fields.gff,
  timeSinceInstall: state.workflow.fields.timeSinceInstall,
  componentAgeVsInstallDateBool: state.workflow.fields.componentAgeVsInstallDateBool,
  calculateRiskFlag: state.workflow.fields.calculateRiskFlag,
})

export default connect(mapStateToProps)(Step2)