import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

import BayesViewer from '@e2grnd/ui-core/dist/components/bayes/BayesViewer'
import BeliefTable from '@e2grnd/ui-core/dist/components/bayes/BeliefTable'
import Dialog from '@e2grnd/ui-core/dist/components/ImageDialog'
import Close from '@e2grnd/ui-core/dist/components/ImageDialogClose'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'
import TextArea from '@e2grnd/ui-core/dist/containers/TextArea'

function BayesNetBuilderProp({ results, problemType, whichNetwork1, whichNetwork2, whichNetwork21, whichNetwork2a, whichNetwork2b }) {
  let bayesNet01
  let bayesNet02
  let whichString = '0'
  let networkName
  let resultingNetworkName01
  let resultingNetworkNameBayes01
  let resultingNetworkName02
  let resultingNetworkNameBayes02
  let items = []
  let networkNames = []
  let networkNamesBayes = []
  let indexEval = 0
  let net
  let networkIndices = []
  if(problemType === 'PROB1') {
    networkName = whichNetwork1
    resultingNetworkName01 = networkName + ' Network'
    resultingNetworkNameBayes01 = 'Bayes Net Output File for ' + networkName + ' Network'
  } else if(problemType === 'PROB2') {
    if(whichNetwork21 === 'Cracking') {
      networkName = whichNetwork2a
      resultingNetworkName01 = networkName + ' Network'
      resultingNetworkNameBayes01 = 'Bayes Net Output File for ' + networkName + ' Network'
    } else {
      if(whichNetwork21 === 'Thinning') {
        networkName = whichNetwork2b
      } else {
        networkName = whichNetwork2
      }
      if(networkName !== 'Sulfidation' && networkName !== 'High Temperature Naphthenic Corrosion' && networkName !== 'High Temperature H2-H2S Corrosion' && networkName !== 'Ammonium Chloride Corrosion' && networkName !== 'Other Corrosion') {
        resultingNetworkName01 = networkName + ' Network'
        resultingNetworkNameBayes01 = 'Bayes Net Output File for ' + networkName + ' Network'
      } else {
        let j = 0
        for(let i = 0; i < results.poppedJsonOutAll_Labels.length; i += 1) {
          if(Object.keys(JSON.parse(results.poppedJsonOutAll[i])).length !== 0) {
            items.push(<E2GSelectFieldOption label={results.poppedJsonOutAll_Labels[i]} value={j.toString()} key={j}/>)
            networkNames.push(results.poppedJsonOutAll_Labels[i])
            networkNamesBayes.push('Bayes Net Output File for ' + results.poppedJsonOutAll_Labels[i])
            networkIndices.push(i)
            j = j + 1
          }
        }
      }
    }
  }
  if(typeof results.poppedJsonOut !== 'undefined') {
    bayesNet01 = JSON.parse(results.poppedJsonOut)
  }

  if(typeof results.poppedJsonOutAll !== 'undefined') {
    indexEval = parseInt(results.whichNetworkSelect[0])
    const networkIndex = networkIndices[indexEval]
    net = JSON.parse(results.poppedJsonOutAll[networkIndex])
  }

  let nodeNew
  const [states, setStates] = useState([])
  const [beliefs, setBeliefs] = useState([])

  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  const onNodeClick = (node) => {
    if(node) {
      if(problemType === 'PROB2' && (((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'High Temperature H2-H2S Corrosion' || whichNetwork2b === 'High Temperature Naphthenic Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion' || whichNetwork2b === 'Other Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'High Temperature H2-H2S Corrosion' || whichNetwork2 === 'High Temperature Naphthenic Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion' || whichNetwork2 === 'Other Corrosion') && whichNetwork21 === 'unknown'))) {
        if(typeof results.poppedJsonOutAll !== 'undefined' && JSON.stringify(results.poppedJsonOutAll) !== '{}') {
          nodeNew = net.nodes.find((n) => n.name === node)
        }
      } else {
        if(results.whichNetworkSelect[0] === '0') {
          nodeNew = bayesNet01.nodes.find((n) => n.name === node)
        }
      }
    }
    if(typeof nodeNew.states !== 'undefined' && typeof nodeNew.belief !== 'undefined') {
      setStates(nodeNew.states.states || [])
      setBeliefs(nodeNew.belief || [])
    } else {
      setStates([])
      setBeliefs([])
    }
  }

  useEffect(() => {
    setStates([])
    setBeliefs([])
  }, [whichString, results.whichNetworkSelect])

  return (
    <div>
      <div className="cf w-100">
        {problemType === 'PROB2' && (((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'High Temperature H2-H2S Corrosion' || whichNetwork2b === 'High Temperature Naphthenic Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion' || whichNetwork2b === 'Other Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'High Temperature H2-H2S Corrosion' || whichNetwork2 === 'High Temperature Naphthenic Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion' || whichNetwork2 === 'Other Corrosion') && whichNetwork21 === 'unknown'))
          ? (
            <E2GSelectFieldIndexer name="whichNetworkSelect" value={results.whichNetworkSelect[0]} label="Select Network to View">
              {items}
            </E2GSelectFieldIndexer >
          )
          : <E2GSelectFieldIndexer name="whichNetworkSelect" value={results.whichNetworkSelect[0]} label="Select Network to View">
            <E2GSelectFieldOption label={resultingNetworkName01} value="0" />
          </E2GSelectFieldIndexer >}
      </div>
      <Stack>
        <div className="cf w-100">
          {problemType === 'PROB2' && (((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'High Temperature H2-H2S Corrosion' || whichNetwork2b === 'High Temperature Naphthenic Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion' || whichNetwork2b === 'Other Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'High Temperature H2-H2S Corrosion' || whichNetwork2 === 'High Temperature Naphthenic Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion' || whichNetwork2 === 'Other Corrosion') && whichNetwork21 === 'unknown'))
            ? (
              <div>
                <div className="fl w-100">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={toggleOpen}
                  >
                    {networkNamesBayes[indexEval]}
                  </Button>
                  <Dialog
                    title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                    modal={false}
                    open={open}
                    fullWidth
                  >
                    <TextArea label={networkNamesBayes[indexEval]} style={{ height: '50vh' }} value={JSON.stringify(net, null, 2)} disabled/>
                  </Dialog>
                </div>
                <div className="pa5">
                  <p>First, click GENERATE LAYOUT to view the network. Then click any nature node (green) to view the corrosponding belief table. Note, there are no belief tables for decision (blue) and utility (orange) nodes.</p>
                </div>
                <div className="fl w-third-l w-50-m w-100">
                  <BeliefTable states={states} beliefs={beliefs} />
                </div>
                <div className="fl w-two-thirds-l w-50-m w-100">
                  <Box height={500}> <BayesViewer bayesNet={net} onNodeClick={onNodeClick} /> </Box>
                </div>
              </div>
            )
            : <div>
              {results.whichNetworkSelect[0] === '0'
                ? (
                  <div>
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                        {resultingNetworkNameBayes01}
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label={resultingNetworkNameBayes01} style={{ height: '50vh' }} value={JSON.stringify(bayesNet01, null, 2)} disabled/>
                      </Dialog>
                    </div>
                    <div className="pa5">
                      <p>First, click GENERATE LAYOUT to view the network. Then click any nature node (green) to view the corrosponding belief table. Note, there are no belief tables for decision (blue) and utility (orange) nodes.</p>
                    </div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNet01} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : null}
            </div>}
        </div>
      </Stack>
    </div>
  )
}

BayesNetBuilderProp.propTypes = {
  results: PropTypes.object,
  problemType: PropTypes.string,
  whichNetwork1: PropTypes.string,
  whichNetwork2: PropTypes.string,
  whichNetwork21: PropTypes.string,
  whichNetwork2a: PropTypes.string,
  whichNetwork2b: PropTypes.string,
}

const mapStateToProps = (state) => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  problemType: state.workflow.fields.problemType,
  whichNetwork1: state.workflow.fields.whichNetwork1,
  whichNetwork2: state.workflow.fields.whichNetwork2,
  whichNetwork21: state.workflow.fields.whichNetwork21,
  whichNetwork2a: state.workflow.fields.whichNetwork2a,
  whichNetwork2b: state.workflow.fields.whichNetwork2b,
})

export default connect(mapStateToProps, null)(BayesNetBuilderProp)
