/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import SubStepper from '@e2grnd/ui-core/dist/containers/SubStepper'

function Step2({ problemType, style, whichNetwork2b, whichNetwork21, whichNetwork2 }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB1'
        ? (
          <div className="cf w-100">
            <SubStepper subStepIndex={0} />
          </div>
        )
        : ''}
      {problemType === 'PROB2'
        ? (
          <div className="cf w-100">
            {((whichNetwork2b === 'Sulfidation' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'Sulfidation' && whichNetwork21 === 'unknown')) || ((whichNetwork2b === 'High Temperature Naphthenic Corrosion' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'High Temperature Naphthenic Corrosion' && whichNetwork21 === 'unknown')) || ((whichNetwork2b === 'High Temperature H2-H2S Corrosion' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'High Temperature H2-H2S Corrosion' && whichNetwork21 === 'unknown')) || (whichNetwork2b === 'Ammonium Chloride Corrosion' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'Ammonium Chloride Corrosion' && whichNetwork21 === 'unknown')
              ? (
                <SubStepper subStepIndex={2} />
              )
              : <div>
                {(whichNetwork2b === 'Other Corrosion' && whichNetwork21 === 'Thinning') || (whichNetwork2 === 'Other Corrosion' && whichNetwork21 === 'unknown')
                  ? (
                    <SubStepper subStepIndex={2} />
                  )
                  : <SubStepper subStepIndex={1} />}
              </div>}
          </div>
        )
        : ''}
    </div>
  )
}

Step2.propTypes = {
  whichNetwork2b: PropTypes.string,
  whichNetwork21: PropTypes.string,
  whichNetwork2: PropTypes.string,
  problemType: PropTypes.string,
  style: PropTypes.object,
}

const mapStateToProps = state => ({
  whichNetwork2b: state.workflow.fields.whichNetwork2b,
  whichNetwork21: state.workflow.fields.whichNetwork21,
  whichNetwork2: state.workflow.fields.whichNetwork2,
  problemType: state.workflow.fields.problemType,
  style: state.ui.style,
})

export default connect(mapStateToProps)(Step2)